<template>
  <footer class="footer mt-6">
    <div class="content has-text-centered">
      <p>
        <strong>©2022 Genensis</strong> by
        <a href="https://nicolas-godin.fr/">Nicolas Godin</a> <br />
        <router-link to="/mentions-legales">Mentions légales & CGU</router-link>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "GenesisFooter",
};
</script>

<style lang="scss" scoped></style>
