<template>
  <div class="mentions">
    <h1 class="title">Mentions Legales et CGU</h1>
    <h3 class="subtitle">Responsabilité des contenus</h3>
    <p>
      Coordonnées de l’éditeur: Simon Nicolas chemin Vernes 38250 LANS EN
      VERCORS
    </p>
    <h3>Responsable publication:</h3>
    <p>Simon Nicolas</p>
    <h3 class="subtitle">Hébergement et réalisation Hébergement:</h3>

    <p>
      Planet Hoster 4416 Rue Louis B. Mayer, Laval, QUEBEC H7P 0G1, Canada +33
      (0)1 76 60 41 43 <br />
      www.planethoster.net <br />
      Réalisation: Godin Nicolas Aucune information personnelle n’est collectée
      à votre insu, Aucune information personnelle n’est cédée à des tiers.
    </p>
  </div>
</template>

<script>
export default {
  name: "MentionLegalesPage",
};
</script>

<style lang="scss" scoped>
.mentions {
  min-height: 100vh;
  margin-left: 5rem;
  margin-right: 5rem;
  p,
  h3 {
    text-align: start;
    margin-top: 2rem;
  }
  h1 {
    margin-top: 2rem;
  }
}
</style>
