<template>
  <section class="hero mt-6">
    <h2 class="has-font-gabriola" id="title">Les formations Genesis</h2>
    <p class="has-text-secondary formation">
      La formation dispensée au sein de l’Ecole permet à terme la pratique de la
      MetaHomeopathie dans le cadre privé ou professionnel. Cette méthode
      spécifique, qui est une spécialité de Naturopathie, nécessite un
      apprentissage théorique et pratique rigoureux pour être utilisée.
    </p>
    <p class="has-text-secondary formation">
      Nos formateurs, tous professionnels en exercice, sont là pour assurer
      cette transmission dans les règles de l’art. Notre formation est ouverte à
      tous et à toutes,
      <strong> néanmoins un bagage médical ou paramédical est conseillé</strong>
      (Infirmier(es), sage-femmes, ostéopathes, kinésithérapeutes, thérapeutes
      manuels, soignants, étudiants en santé, etc.).
    </p>
    <p class="has-text-secondary formation">
      Les candidatures sont étudiées individuellement et font l’objet d’un
      entretien avec le responsable de formation avant toute inscription. Les
      praticiens en exercice disposant déjà d’une formation complète en
      Ostéopathie ou kinésiologie peuvent accéder à la formation dites « avec
      pré requis », contenant une partie pratique beaucoup plus concise, puisque
      déjà maîtrisée.
    </p>
  </section>
</template>

<script>
export default {
  name: "SansPreRequis",
  mounted() {
    setTimeout(() => {
      document.getElementById("title").scrollIntoView({ behavior: "smooth" });
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.formation {
  margin: 5rem auto 1rem;
  text-align: justify;
  max-width: 50%;
  font-size: 1.3rem;
}
@media screen and (max-width: 880px) {
  .formation {
    max-width: 75%;
  }
}
</style>
