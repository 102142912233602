<template>
  <section class="hero is-fullheight mt-6 is-justify-content-space-evenly">
    <h2 class="has-font-gabriola p-1" id="title">
      lieu de formation : L'Escandille
    </h2>
    <div>
      <h4 class="has-text-secondary has-font-gabriola-sub p-2">
        En plein cœur du parc naturel régional du Vercors, dans le département
        de l’Isère, sur la commune de Autrans-Méaudre en Vercors.
      </h4>
    </div>
    <div class="photo-cairn">
      <figure class="image is-1by1">
        <img src="@/assets/img/salle.jpeg" alt="salle de seminaire" />
      </figure>
      <figure class="image is-1by1">
        <img src="@/assets/img/rest.jpeg" alt="salle de restaurant" />
      </figure>
      <figure class="image is-1by1">
        <img src="@/assets/img/chamb.jpeg" alt="chambre a coucher" />
      </figure>
      <figure class="image is-1by1">
        <img src="@/assets/img/pisc.jpeg" alt="piscine" />
      </figure>
      <figure class="image is-1by1">
        <img src="@/assets/img/neige.jpeg" alt="escandille hiver" />
      </figure>
    </div>
  </section>
</template>

<script>
export default {
  name: "LieuDeFormation",
  mounted() {
    setTimeout(() => {
      document.getElementById("title").scrollIntoView({ behavior: "smooth" });
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.photo-cairn {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding: 1rem;
}
</style>
