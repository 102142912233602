<template>
  <section class="hero is-fullheight" id="title">
    <div class="grid-info">
      <div class="info">
        <p class="has-font-gabriola-sub">Ou trouver ...</p>

        <label
          class="checkbox m-2"
          v-for="checkboxe in markers"
          :key="checkboxe.id"
        >
          <input type="checkbox" class="" v-model="checkboxe.check" />

          <span class="has-text-secondary subtitle is-5 ml-3">
            {{ checkboxe.name }}</span
          >
        </label>
      </div>
      <div class="info"><maps-leaflet :markers="markers" /></div>
    </div>
  </section>
</template>

<script>
import { latLng } from "leaflet";
import MapsLeaflet from "@/components/public/MapsLeaflet.vue";
export default {
  name: "InfosUtiles",
  components: {
    MapsLeaflet,
  },
  data() {
    return {
      markers: [
        {
          id: 0,
          name: "Le cabinet",
          adresse: "51 Av. Léopold Fabre Lans en vercors",
          telephone: "0681389671",
          geoloc: latLng(45.127699, 5.589008),
          check: true,
          //icon: require("../assets/iconeMaps/cabmed.png"),
          iconSize: [32, 32],
        },
        {
          id: 1,
          name: "L'Escandille",
          adresse: "Route de la sure , 38880 Autrans",
          telephone: "0681389671",
          geoloc: latLng(45.183606, 5.553421),
          check: true,
          //icon: require("../assets/iconeMaps/salle.png"),
          iconSize: [32, 32],
        },
        {
          id: 2,
          name: "O.T Lans en Vercors",
          geoloc: latLng(45.129298, 5.584312),
          check: true,
          //icon: require("../assets/iconeMaps/info.png"),
          iconSize: [32, 32],
        },
        {
          id: 3,
          name: "O.T Autrans-Meaudre",
          geoloc: latLng(45.17458, 5.543196),
          check: true,
          // icon: require("../assets/iconeMaps/hotel1.png"),
          iconSize: [32, 32],
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("title").scrollIntoView({ behavior: "smooth" });
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.info:nth-child(1) {
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-right: 33%;
}
.info:nth-child(2) {
  grid-area: map;
}

@media screen and (max-width: 780px) {
  .grid-info {
    display: block;
    padding: 0;
  }
}
@media screen and (max-width: 880px) {
  .info:nth-child(1) {
    grid-area: info;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-right: 10%;
  }
  .info {
    & .ml-3 {
      margin-left: 0 !important;
    }
  }
}
</style>
