<template>
  <section class="hero is-fullheight">
    <div class="grid-card">
      <div class="grid-card-child">
        <h1 class="has-font-gabriola">Les formations</h1>
      </div>
      <div
        class="grid-card-child"
        :class="{ selectedCard: view === 'nos-formations' }"
      >
        <div class="card box-shadow">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="@/assets/img/logo_cours.jpeg" alt="photo du labo" />
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <p class="has-font-gabriola-sub" v-if="view === 'nos-formations'">
                Nos formations
              </p>
              <button-anime
                :btnName="card1"
                :width="320"
                v-else
                @click="scrollInView"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="grid-card-child"
        :class="{ selectedCard: view === 'salle-de-formations' }"
      >
        <div class="card box-shadow">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="@/assets/img/escan.jpeg" alt="photo d'escandille'" />
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <p
                class="has-font-gabriola-sub"
                v-if="view === 'salle-de-formations'"
              >
                L'escandille
              </p>
              <button-anime
                :btnName="card2"
                :width="320"
                v-else
                @click="scrollInView"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <sans-pre-requis v-if="view === 'nos-formations'" />
    <lieu-de-formation v-if="view === 'salle-de-formations'" />
  </section>
</template>

<script>
import buttonAnime from "@/components/inAllComponents/button.vue";
import SansPreRequis from "@/components/public/FormationsPage/SansPreRequis.vue";

import LieuDeFormation from "@/components/public/FormationsPage/LieuDeFormation.vue";
export default {
  name: "FormationPage",
  components: { buttonAnime, SansPreRequis, LieuDeFormation },
  props: ["view"],
  data() {
    return {
      card1: [
        {
          id: 0,
          name: "Nos formations",
          router: {
            name: "formations",
            params: { view: "nos-formations" },
          },
          width: "320px",
          strokeDasharray1: 140,
          strokeDasharray2: 540,
          strokeDashoffset: -474,
          dashHover: 760,
        },
      ],
      card2: [
        {
          id: 0,
          name: "L'escandille",
          router: {
            name: "formations",
            params: { view: "salle-de-formations" },
          },
          width: "320px",
          strokeDasharray1: 140,
          strokeDasharray2: 540,
          strokeDashoffset: -474,
          dashHover: 760,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-card {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.5fr 1fr;
  grid-template-areas:
    "title title"
    "card1 card2";
}
#caduce {
  position: absolute;
  height: 55px;
  width: 55px;
  left: auto;
}
@media screen and (max-width: 780px) {
  .grid-card {
    grid-template-columns: 1fr;
    grid-template-rows: 10rem 1fr 1fr;
    grid-gap: 1rem;
    grid-template-areas:
      "title  "
      "card1  "
      "card2  ";
  }
}
@media screen and (max-width: 1100px) {
  div.grid-card-child:nth-child(3) {
    grid-area: card2;
  }
  div.grid-card-child:nth-child(4) {
    grid-area: card1;
  }
}
</style>
