<template>
  <section class="hero is-fullheight mt-6 is-justify-content-space-evenly">
    <h2 class="has-font-gabriola" id="title">Un laboratoire associatif</h2>
    <div class="explication">
      <p class="has-text-secondary">
        Dans un souci constant de pérennisation de la méthode et des dilutions
        Genesis, le laboratoire est structuré en association à but non lucratif
        (loi 1901).
      </p>
      <p class="has-text-secondary mt-3">
        Cela garantit la diffusion des produits de MetaHomeopathie au prix le
        plus bas, ainsi qu’une parfaite maîtrise de la qualité des dilutions.
        Seuls les praticiens ayant suivi le cursus de formation
        <strong>Genesis</strong> peuvent adhérer à l’association.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "LaboExplications",
  mounted() {
    setTimeout(() => {
      document.getElementById("title").scrollIntoView({ behavior: "smooth" });
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.explication {
  max-width: 75%;
  margin: 0 auto;
  & p {
    text-align: justify;
    font-size: 1.3rem;
  }
}
</style>
